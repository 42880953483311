<script>
import TopNavbar from './TopNavbar.vue';
import DashboardContent from './Content.vue';
import settingsService from '@/services/Settings/settingsService';

export default {
  components: {
    TopNavbar,
    DashboardContent,
  },
  async mounted() {
    console.debug('mounted dashboard layout');
    this.socialTransformationEnabled =
      await settingsService.socialTransformationEnabled();
  },
  computed: {
    userClaim() {
      const Claim = this.$store.getters.userClaim;

      return Claim.role;
    },
  },
  data() {
    return {
      layout: this.$route.meta.layout,
      modalidade: process.env.VUE_APP_TYPE,
      socialTransformationEnabled: undefined,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>

<template>
  <div class="wrapper">
    <side-bar v-if="!layout">
      <template slot="links">
        <sidebar-link
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />

        <template>
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/users"
            :name="$t('sidebar.users')"
            icon="tim-icons icon-single-02"
          />
          <sidebar-link
            v-if="
              userClaim === 'master' ||
              userClaim === 'gestor' ||
              userClaim === 'curador'
            "
            to="/social-transformation/agents"
            :name="$t('sidebar.social-agent')"
            icon="tim-icons icon-single-02"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/categorias"
            :name="$t('sidebar.categorias')"
            icon="tim-icons icon-tag"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/banks"
            :name="$t('sidebar.banks')"
            icon="tim-icons icon-bank"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/segment-interest"
            :name="$t('sidebar.segment-intereset')"
            icon="tim-icons icon-chart-bar-32"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/states"
            :name="$t('sidebar.states')"
            icon="tim-icons icon-square-pin"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/values"
            :name="$t('sidebar.values')"
            icon="tim-icons icon-coins"
          />
          <sidebar-link
            v-if="userClaim === 'master'"
            to="/investments-profile"
            :name="$t('sidebar.investments-profile')"
            icon="tim-icons icon-badge"
          />
          <sidebar-link
            to="/financing"
            :name="$t('sidebar.financing')"
            icon="tim-icons icon-money-coins"
            v-if="modalidade == 'doacao'"
          ></sidebar-link>

          <sidebar-link
            to="/financing"
            :name="$t('sidebar.financing-equity')"
            icon="tim-icons icon-money-coins"
            v-if="modalidade == 'equity'"
          ></sidebar-link>
          <!-- <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/investors"
            :name="$t('sidebar.investors')"
            icon="tim-icons icon-single-02"
          ></sidebar-link> -->
          <!-- <sidebar-link
            v-if="userClaim === 'master'"
            to="/attachments"
            :name="$t('sidebar.attachments')"
            icon="tim-icons icon-attach-87"
          ></sidebar-link> -->
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/terms-of-use"
            :name="$t('sidebar.terms-of-use')"
            icon="tim-icons icon-single-copy-04"
          ></sidebar-link>
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/logs"
            :name="$t('sidebar.logs')"
            icon="tim-icons icon-notes"
          ></sidebar-link>
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/reports"
            :name="$t('sidebar.reports')"
            icon="tim-icons icon-upload"
          ></sidebar-link>
        </template>

        <!-- <sidebar-link
          v-if="userClaim !== 'master'"
          to="/profile"
          :name="$t('sidebar.profile')"
          icon="tim-icons icon-single-02"
        ></sidebar-link> -->
        <sidebar-link
          v-if="userClaim == 'master'"
          to="/dynamic-fields"
          :name="$t('sidebar.dynamicFields')"
          icon="tim-icons icon-html5"
        ></sidebar-link>
        <sidebar-link
          v-if="userClaim == 'master'"
          to="/settings"
          :name="$t('sidebar.settings')"
          icon="tim-icons icon-html5"
        ></sidebar-link>
      </template>
    </side-bar>

    <div class="main-panel" v-if="!layout">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
    </div>

    <dashboard-content
      @click.native="toggleSidebar"
      v-if="layout"
      :layout="layout"
    >
    </dashboard-content>
  </div>
</template>
